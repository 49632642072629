import { t } from "i18next";
import React, { useEffect } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { useCommissionActivity } from "@sentara/sentara-api-hooks-core";
import { useAuth, useBranding } from "providers";
import { avMedCommissionActivityUrl } from 'utils/constants/Navigation';

export const CommissionActivity = () => {
  const { user } = useAuth();
  const { state, getCommissionActivitySSO } = useCommissionActivity();
  const { brandConfig } = useBranding();

  useEffect(() => {
    if (brandConfig.brandName !== "AVMED") {
      document.title = t('pageTitleADA.commissionActivity');
      fetchSSO();
    }
  }, []);
  const fetchSSO = async () => {
    if (user?.loginID) {
      await getCommissionActivitySSO({ userID: user.loginID });
    }
  };

  return (
    <SSONavigationContent
      header={t("sso.header.commissionActivity")}
      ssoUrl={brandConfig.brandName === "AVMED" ? avMedCommissionActivityUrl : state?.Result?.ssoUrl}
      dataTestId="ssoLinkCommissionsActivity"
      targetName="CommissionActivity"
    />
  );
};

export default CommissionActivity;
