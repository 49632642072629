import { ErrorMessage } from "@hookform/error-message";
import { ApiErrorResponse } from "@sentara/sentara-api-hooks-core";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";
import parse from "html-react-parser";
import { CloseIconBlock, MailIcon } from "organisms/icons";
import { useState } from "react";
import { boolean } from "yup";

type Props = {
  name: string;
};

export const FormError = (props: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <ErrorMessage
      errors={errors}
      name={props.name}
      render={({ message }) => (
        <div className="pt-1">
          <span className="visually-hidden">{t("error")} </span>
          <span className="text-danger">{message}</span>
        </div>
      )}
    />
  );
};

export const GlobalError = (props: Props) => {
  const {
    formState: { errors, isSubmitted },
  } = useFormContext();
  return (
    <ErrorMessage
      errors={errors}
      name={props.name}
      render={({ message }) => (
        <>
          {isSubmitted && (
            <div role="alert" className="global-alert">
              {message}
            </div>
          )}
        </>
      )}
    />
  );
};

export const GlobalApiError = ({
  apiError,
  component
}: {
  apiError: ApiErrorResponse | null;
  component?: string;
}) => {
  const refEle = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (apiError && refEle) {
      refEle.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [apiError]);
  if (apiError?.errorCode?.toString() === "404") {
    apiError.error = !component? t("noResult"): t("unableToLocate");
  }
  return apiError?.error ? (
    <div
      ref={refEle}
      id="GlobalApiError"
      data-testid="errMsgGlobalApiError"
      role="alert"
      className="global-alert"
      style={{marginBottom: '10px'}}
    >
      {apiError.error}
    </div>
  ) : null;
};

export const ErrorBy = ({
  isShow,
  message,
  closeButton = false,
  hideErrorMessage = () => { }
}: {
  isShow: boolean;
  message: string;
  closeButton?: boolean;
  hideErrorMessage?: Function
}) => {
  const refEle = useRef<HTMLDivElement | null>(null);
  const [isClose, setIsclose] = useState(false)
  useEffect(() => {
    if (isShow && message && refEle) {
      refEle.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isShow]);

  const HideErrorMessage = () => {
    hideErrorMessage();
  }

  return (
    <>
      {isShow && (
        <div
          ref={refEle}
          role="alert"
          className="alert alert-global alert-danger global-error-link d-flex align-items-center gap-3"
        >
          <div className="flex-1">
            {parse(message)}
          </div>
          {closeButton && (
            <button
              type="button"
              data-testid="CloseErrorBtn"
              className="btn-link border-0 bg-transparent d-inline-flex align-items-center text-decoration-none"
              onClick={HideErrorMessage}
              aria-label={t("close")}
            ><CloseIconBlock />
            </button>
          )}
        </div>
      )}
    </>
  );
};
