import { t } from "i18next";
import { SecondaryButton, AccordionTable } from "@sentaraui/optimahealth_web";
import { useNavigate } from "react-router";
import { UNPAID_GROUP_BALANCE } from "utils/constants/Routes";
import { UnpaidBalanceNotes } from "./UnpaidGroupBalanceTable";
import UnpaidGroupBalanceHeader from "./UnpaidGroupBalanceHeader";
import { EmployerGroupResponse } from "@sentara/sentara-api-hooks-core";
import { useEffect, useState } from "react";
import { convertToDate } from "utils/helpers/DateFormate";
import { amountFormat } from "utils/helpers/HelperFn";
import { DeviceTypes } from "utils/model/CommonTypes";
import { useGlobalState } from "providers";
import { downloadXLSX } from "utils/helpers/DownloadFiles";

type Props = {
  state: Array<EmployerGroupResponse>;
  groupId: string;
};
const UnpaidGroupBalanceDetails = ({ state, groupId }: Props) => {
  const navigate = useNavigate();
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  const [groupName, setGroupName] = useState("");
  let total = 0;

  useEffect(() => {
    if (state?.length) {
      setGroupName(state[0].GroupName);
    }
  }, [state]);

  const handleDownload = (jsonData:any) =>{
    const customJson = jsonData.map((item:any)=>{
      return {
        "Invoice Number": item.InvoiceNumber,
        "Invoice Date": convertToDate(item.InvoiceDate),
        "Amount Billed": amountFormat(item.InvoiceBalance),
        "Amount Due": amountFormat(item.AmountDue),
        "Days Outstanding": item.DaysOverdue,
        "Additional Information": item.AdditionalInfo,
      }
    })
    downloadXLSX(customJson)
  }

  return (
    <div>
      <UnpaidGroupBalanceHeader />
      <div className="border light-grey-bg border-radius10 p-3 mb-4">
        <UnpaidBalanceNotes />
      </div>
      <div className="d-flex flex-column gap-3 mb-4">
        <div>
          <strong data-testid="txtGroupNumberLabel">
            {t("unpaidGroupBalance.content.groupNumber")}:
          </strong>{" "}
          <span data-testid="txtGroupNumberValue">{groupId}</span>
        </div>
        <div>
          <strong data-testid="txtGroupNameLabel">
            {t("unpaidGroupBalance.content.groupName")}:
          </strong>{" "}
          <span data-testid="txtGroupNameValue">{groupName}</span>
        </div>
      </div>
      <div className="mb-4">
        <SecondaryButton
          data-testid="btnPerformdownloadGroupNumber"
          className={"secondary_btn btn_block_mob"}
          onClick={() => handleDownload(state)}
        >
          {t("button.label.downloadAll")}
        </SecondaryButton>
      </div>
      {!isMobile && (
        <div className="table-responsive mb-4">
          <table className="table-custom-style" data-testid="tblInvoiceTable">
            <thead>
              <tr>
                <th scope="col">
                  {t("unpaidGroupBalance.content.invoiceNumber")}
                </th>
                <th scope="col">
                  {t("unpaidGroupBalance.content.invoiceDate")}
                </th>
                <th scope="col">
                  {t("unpaidGroupBalance.content.amountBilled")}
                </th>
                <th scope="col">{t("unpaidGroupBalance.content.amountDue")}</th>
                <th scope="col">
                  {t("unpaidGroupBalance.content.daysOutstanding")}
                </th>
                <th scope="col">
                  {t("unpaidGroupBalance.content.additionalInformation")}
                </th>
              </tr>
            </thead>
            <tbody>
              {state.map((item, key: number) => {
                total = total + item.InvoiceBalance * 1;
                return (
                  <tr key={item.InvoiceNumber + key}>
                    <td scope="row">{item.InvoiceNumber}</td>
                    <td>{convertToDate(item.InvoiceDate)}</td>
                    <td>{amountFormat(item.AmountDue)}</td>
                    <td>{amountFormat(item.InvoiceBalance)}</td>
                    <td>{item.DaysOverdue}</td>
                    <td>{item.AdditionalInfo}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {isMobile && (
        <div className="mb-4">
          <AccordionTable
            initialActiveIndex={0}
            id="unpaid_group_details_table"
            items={state.map((item, key: number) => {
              total = total + item.InvoiceBalance * 1;
              return {
                title: (
                  <div>
                    <div>{t("unpaidGroupBalance.content.groupNumber")}</div>
                    <div>{item.InvoiceNumber}</div>
                  </div>
                ),
                id: "unpaid_group_details_table_data",
                content: (
                  <>
                    <div className="mb-3">
                      <div>{t("unpaidGroupBalance.content.invoiceDate")}</div>
                      <div>{convertToDate(item.InvoiceDate)}</div>
                    </div>
                    <div className="mb-3">
                      <div>{t("unpaidGroupBalance.content.amountBilled")}</div>
                      <div>{amountFormat(item.AmountDue)}</div>
                    </div>
                    <div className="mb-3">
                      <div>{t("unpaidGroupBalance.content.amountDue")}</div>
                      <div>{amountFormat(item.InvoiceBalance)}</div>
                    </div>
                    <div className="mb-3">
                      <div>
                        {t("unpaidGroupBalance.content.daysOutstanding")}
                      </div>
                      <div>{item.DaysOverdue}</div>
                    </div>
                    <div className="mb-3">
                      <div>
                        {t("unpaidGroupBalance.content.additionalInformation")}
                      </div>
                      <div>{item.AdditionalInfo}</div>
                    </div>
                  </>
                ),
              };
            })}
          />
        </div>
      )}
      <div className="d-flex flex-column gap-3 mb-4">
        <div>
          <strong data-testid="txtUnAppliedAdjustmentsLabel">
            {t("unpaidGroupBalance.content.unappliedAdjustments")}:
          </strong>{" "}
          <span data-testid="txtUnAppliedAdjustmentsValue">None</span>
        </div>
        <div>
          <strong data-testid="txtTotalAmountDueLabel">
            {t("unpaidGroupBalance.content.totalAmountDue")}:
          </strong>{" "}
          <span data-testid="txtTotalAmountDueValue">
            {total < 0
              ? `(${amountFormat(total, true).replace("-", "")})`
              : `${amountFormat(total, true).replace("-", "")}`}
          </span>
        </div>
      </div>
      <hr className="my-4" />
      <div className="mb-4">
        <SecondaryButton
          data-testid="performNewButton"
          className={"secondary_btn btn_block_mob mb-4"}
          onClick={() => navigate(`/app/${UNPAID_GROUP_BALANCE}`)}
        >
          {t("button.label.performANewSearch")}
        </SecondaryButton>
      </div>
    </div>
  );
};

export default UnpaidGroupBalanceDetails;
