
import { useTranslation } from "react-i18next";
import { COMMISSION_POLICY_MEDICARE_PLANS } from "utils/constants/Constants";

export default function CommissionPolicyMedicarePlanTable() {
  const { t } = useTranslation();
  return (
    <div className="row justify-content-center mb-3">
      <div className="col-lg-10 col-md-9 col-sm-12 col-12">
        <div className="text-center mb-3">
          <strong> {t("commissionPolicy.medicarePlansTableHeader")}</strong>
        </div>
        <table className="table-custom-style w-100" data-testid="tblSmallGroupAndMidMarket">
          <thead>
            <tr>
              <th scope="col">{COMMISSION_POLICY_MEDICARE_PLANS.empty}</th>
              <th scope="col" colSpan={2}>{t("commissionPolicy.medicarePlansTableHeaderYear1")}</th>
              <th scope="col" colSpan={2}>{t("commissionPolicy.medicarePlansTableHeaderYear1")}</th>
              <th scope="col" colSpan={2}>{t("commissionPolicy.medicarePlansTableHeaderYear2Pluse")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td scope="row" colSpan={2}>{COMMISSION_POLICY_MEDICARE_PLANS.initialEnrollment}</td>
              <td colSpan={2}>{COMMISSION_POLICY_MEDICARE_PLANS.renewal}</td>
              <td colSpan={2}>{COMMISSION_POLICY_MEDICARE_PLANS.renewal}</td>
            </tr>
            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.plan}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.currency}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.plan}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.currency}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.plan}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.currency}</td>
            </tr>

            <tr className="font-fourteen">
              <td scope="row" rowSpan={2}>{COMMISSION_POLICY_MEDICARE_PLANS.broker}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1Price}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price}</td>
            </tr>

            <tr className="font-fourteen">
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan0Price}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price}</td>
            </tr>

            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td colSpan={2}>{COMMISSION_POLICY_MEDICARE_PLANS.incentiveProgram}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.currency}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
            </tr>


            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY_MEDICARE_PLANS.SHPHRA}</td>
              <td colSpan={2}>{COMMISSION_POLICY_MEDICARE_PLANS.SHPHRADSNPIncentive}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2Price}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
              <td>{COMMISSION_POLICY_MEDICARE_PLANS.empty}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  );
}
