import * as XLSX from "xlsx";

export const downloadXLSX = (customJson:any) =>{

    try {
        const worksheet = XLSX.utils.json_to_sheet(customJson);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook,worksheet,'Sheet 1')
    
        if(workbook){
          const workbookBlob = XLSX.write(workbook,{ bookType:'xlsx', type:'array'})
          const workbookData = new Blob([workbookBlob],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    
          const url = URL.createObjectURL(workbookData)
          const a = document.createElement('a');
    
          a.href = url;
          a.download = 'Past Due Balance Report.xlsx';
          a.click();
          URL.revokeObjectURL(url);
        }        
    } catch (error) {
        console.log(error);
    }

}