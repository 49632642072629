export const guidelines = [
  {
    id: "1",
    content: "commissionPolicy.guidelinesContent1",
  },
  {
    id: "2",
    content: "commissionPolicy.guidelinesContent2",
  },
  {
    id: "3",
    content: "commissionPolicy.guidelinesContent3",
  },
  {
    id: "4",
    content: "commissionPolicy.guidelinesContent4",
  },
  {
    id: "5",
    content: "commissionPolicy.guidelinesContent5",
  },
];

export const group_plan = [
  {
    id: "1",
    content: "commissionPolicy.groupPlanContent1",
  },
  {
    id: "2",
    content: "commissionPolicy.groupPlanContent2",
  },
  {
    id: "3",
    content: "commissionPolicy.groupPlanContent3",
  },
  {
    id: "4",
    content: "commissionPolicy.groupPlanContent4",
  },
  {
    id: "5",
    content: "commissionPolicy.groupPlanContent5",
  },
];

export const commission_plan = [
  {
    id: "1",
    content: "commissionPolicy.commissionScheduleContent1",
  },
  {
    id: "2",
    content: "commissionPolicy.commissionScheduleContent2",
  }
];

export const qualificationCriteria = [
  {
    id: "1",
    content: "commissionPolicy.qualificationCriteriaContent1",
  },
  {
    id: "2",
    content: "commissionPolicy.qualificationCriteriaContent2",
  },
  {
    id: "3",
    content: "commissionPolicy.qualificationCriteriaContent3",
  },
];

export const bonusPayout = [
  {
    id: "1",
    content: "commissionPolicy.bonusPayoutContent1",
  },
  {
    id: "2",
    content: "commissionPolicy.bonusPayoutContent2",
  },
];
