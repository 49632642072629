
import { useTranslation } from "react-i18next";
import { COMMISSION_POLICY } from "utils/constants/Constants";

export default function CommissionPolicyTable1() {
  const { t } = useTranslation();
  return (
    <div className="row justify-content-center">
      <div className="col-lg-10 col-md-9 col-sm-12 col-12">
        <div className="text-center mb-3 mt-2">
          <strong> {t("commissionPolicy.tableHeader1")}</strong>
          <strong className="redhat_bolditalic">{t("commissionPolicy.commissionScheduleEdge")}</strong>
          <strong> {t("commissionPolicy.tableHeader1.1")}</strong>
        </div>
        <table className="table-custom-style w-100" data-testid="tblSmallGroupAndMidMarket">
          <thead>
            <tr>
              <th scope="col">{t("commissionPolicy.billedContracts")}</th>
              <th scope="col">{t("commissionPolicy.newSale")}</th>
              <th scope="col">{t("commissionPolicy.renewal")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY.ENTROLLED1}</td>
              <td>{COMMISSION_POLICY.NEWSALES1}</td>
              <td>{COMMISSION_POLICY.RENEWAL1}</td>
            </tr>
            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY.ENTROLLED2}</td>
              <td>{COMMISSION_POLICY.NEWSALES2}</td>
              <td>{COMMISSION_POLICY.RENEWAL2}</td>
            </tr>
            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY.ENTROLLED3}</td>
              <td>{COMMISSION_POLICY.NEWSALES3}</td>
              <td>{COMMISSION_POLICY.RENEWAL3}</td>
            </tr>
            <tr className="font-fourteen">
              <td scope="row">{COMMISSION_POLICY.ENTROLLED4}</td>
              <td>{COMMISSION_POLICY.NEWSALES4}</td>
              <td>{COMMISSION_POLICY.RENEWAL4}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
